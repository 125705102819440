<template>
  <div id="app" class="">
    <router-view></router-view>  
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      
    }
  },
  created() {
    // console.log(this.$router);
  },
}
</script>
<style>
 /deep/ body{
  background-color: #f3f4f6;
}

 /*滚动条整体部分*/
  .mytable-scrollbar ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /*滚动条的轨道*/
        .mytable-scrollbar ::-webkit-scrollbar-track {
          background-color: #FFFFFF;
        }
        /*滚动条里面的小方块，能向上向下移动*/
        .mytable-scrollbar ::-webkit-scrollbar-thumb {
          background-color: #d2d2d2;
          border-radius: 8px;
          border: 1px solid #F1F1F1;
          box-shadow: inset 0 0 6px rgba(0,0,0,.2);
        }
        .mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
          background-color: #1890ff;
        }
        .mytable-scrollbar ::-webkit-scrollbar-thumb:active {
          background-color: #1890ff;
        }
        /*边角，即两个滚动条的交汇处*/
        .mytable-scrollbar ::-webkit-scrollbar-corner {
          background-color: #FFFFFF;
        }
</style>