import { Vika } from "@vikadata/vika";
const vika = new Vika({ token: "uskG6LVmEvLqqR6braIBvQY", fieldKey: "id" });

const returnVika = {
    record(name, sheetId, Query) {
        /**
         * name : case name
         * sheetID : vika 对应的表ID
         * Query: 需要查询的内容 前端表单提交数组
         */
        let _sheetId = sheetId ? sheetId : "dst03wvk7rx55nN9P1";
        const datasheet = vika.datasheet(_sheetId);
        switch (name) {     // switch  case 使用"{}" 避免 eslint  报错 
            case "report": {
                let _query = '';
                if(Query){                    
                    _.forEach(Query, (val, key)=>{
                        if(val){
                            _query += 'find("'+ val +'", {'+ key +'}) > 0,'
                        }
                    })
                    _query = 'AND('+ _query.substr(0, _query.length - 1) +')';
                        _query = {filterByFormula: _query}
                }else {
                   _query = {};
                } 
                // return _query;      
                return new Promise((resolve,reject)=>{                
                    datasheet.records.query(_query).then(response => {
                        if (response.success) {
                         resolve(response.data);
                        } else {
                            reject(response);
                        }
                    });
                });
            }
            case "userList" :
                return new Promise((resolve,reject)=>{
                    datasheet.records.query({ viewId: "viwib8aUQu1XC"}).then(response => {
                        if (response.success) {
                           resolve(response.data);
                        } else {
                            reject(response);
                        }
                    }); 
                })
            default:    // 栏目表格API
                return new Promise((resolve, reject) => {
                    datasheet.records.query({ filterByFormula: 'AND(find("' + name + '",{所属栏目})>0,find("", {表格地址})>0)', sort: [{ "field": "fld2z7YIaCauR", "order": "asc" }] }).then(response => {
                        if (response.success) {
                            resolve(response.data);
                        } else {
                            reject(response);
                        }
                    });
                });
        }
    }
}

export default returnVika;
